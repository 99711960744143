import React from "react";
import "./Footer.scss";
import path from "../../routes/path";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/image/footer-logo.png";
import siteConfig from "../../config/site-config";
import { Icon } from "@iconify/react/dist/iconify.js";
import googleLogo from "../../assets/image/Google-Play-logo.svg";
import appLogo from "../../assets/image/Apple-logo.svg";
import google from "../../assets/image/google.svg";
import appStore from "../../assets/image/app-store.svg";
import { useSelector } from "react-redux";

const Footer = () => {
    const getProfileData = useSelector((state) => state.userReducer.sessionData);
    return (
        <footer className="footer pt-4">
            <div className="container">
                <div className="row footer-top ">
                    <div className="col-lg-4 col-md-6 mb-4">
                        {/* to={path.home} */}
                        <Link to={path.home} className="footer-logo">
                            <img src={footerLogo} alt={siteConfig.company_name} />
                        </Link>
                        <p>
                            Embarking on a successful diet program is anchored in a
                            fundamental truth – sustaining an energy intake below the
                            breakeven point. The pivotal initial step involves identifying
                            breakeven point.
                        </p>
                        {/* <p className="location">
              <span>
                <Icon icon={"carbon:location-filled"} />
              </span>
              United States
            </p> */}
                    </div>
                    <div className="col-lg-2 col-md-3 col-6 mb-4">
                        <h5>Links</h5>
                        <ul className="list-unstyled">
                            <li>
                                <Link to={path.home} className="text-white">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={path.about_us} className="text-white">
                                    About Us
                                </Link>
                            </li>
                            {getProfileData.user_id && (
                                <li>
                                    <Link to={path.premium} className="text-white">
                                        Premium
                                    </Link>
                                </li>
                            )}
                            {getProfileData.user_id && (
                                <li>
                                    <Link to={path.support} className="text-white">
                                        Support
                                    </Link>
                                </li>
                            )}

                            {/* <li>
                <Link className="text-white">Blog</Link>
              </li> */}
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-3 col-6 mb-4">
                        <h5>Other</h5>
                        <ul className="list-unstyled">
                            {/* <li>
                                <Link className="text-white">User Manual</Link>
                            </li> */}
                            <li>
                                <Link to={path.weight_management} className="text-white">
                                    The ABC’s of weight management
                                </Link>
                            </li>
                            <li>
                                <Link to={path.science_diet} className="text-white">
                                    The Science of diet
                                </Link>
                            </li>
                            <li>
                                <Link to={path.privacy_policy} className="text-white">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link className="text-white" to={path.terms_conditions}>
                                    Terms and Conditions
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-8 col-12 mb-4">
                        <h5 className="mb-4">Download Mobile App</h5>
                        <div className="d-flex mb-4 download-icon">
                            <Link className="mr-2">
                                {/* <img src={appLogo} alt="App Store" /> */}
                                {/* <p>Download on the <b>App Store</b></p> */}
                                <img src={appStore} alt="" />
                            </Link>
                            <Link>
                                {/* <img src={googleLogo} alt="Google Play" /> */}
                                <img src={google} alt="" />
                                {/* <p>Get it on <b>Google Play</b></p> */}
                            </Link>
                        </div>
                        <div className="social-icon">
                            <Link className="text-white">
                                <Icon icon={"ri:facebook-fill"} />
                            </Link>
                            <Link className="text-white">
                                <Icon icon={"ri:instagram-line"} />
                            </Link>
                            <Link className="text-white">
                                <Icon icon={"prime:twitter"} />
                            </Link>
                            <Link className="text-white">
                                <Icon icon={"ri:linkedin-fill"} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col text-center">
                        <p>&copy; 2024 MMI Tech, LLC. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
