import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from "./routes";
import { ToastContainer } from 'react-toastify';
import './lang/i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// ** Import custom components for redux **
import { Provider } from 'react-redux';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// Configure Amplify for file upload to S3
import { S3_Pool_conf } from "./config/aws-amplify";
import { Amplify } from 'aws-amplify'
import axios from "axios";
import { api_base_url } from "./config/config";
import ApiUrlServices from "./networks/ApiUrlServices";
import { userData } from "./config/sessionKeys";
import { userAction } from "./store/userSlice";
import path from "./routes/path";
import queryString from 'query-string';

Amplify.configure(S3_Pool_conf);

// use store for Redux
let persist = persistStore(store);

axios.interceptors.response.use(response => {
    return response;
}, error => {

    let usersData = JSON.parse(localStorage.getItem(userData));
    if (error.response.data.code === 'EXPIRED_TOKEN') {
        if (usersData.trust_this_device) {
            console.log('index error', error.response.data)
            const refreshToken = usersData.refresh_token;
            return axios.post(`${api_base_url}${ApiUrlServices.REFRESH_TOKEN}`,
                {
                    "refresh_token": refreshToken
                })
                .then(response => {
                    usersData["access_token"] = response.data.data.access_token
                    usersData["refresh_token"] = response.data.data.refresh_token

                    localStorage.setItem(userData, JSON.stringify(usersData))
                    // dispatch(userAction.setSessionData(usersData));

                    error.response.config.headers['Authorization'] = `Bearer ${response.data.data.access_token}`;
                    return axios(error.response.config);
                })
                .catch(error => {
                    if (error.response.data.code !== "TOKEN_EXPIRED") {
                        return;
                    }
                    localStorage.clear()
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });

                    // set redirect URI...
                    let myDynamicPath = "";
                    const parsed = queryString.parse(window.location.search);
                    if (Object.entries(parsed).length) {
                        for (const [key, value] of Object.entries(parsed)) {
                            if (!key || !value) {
                                continue;
                            }
                            myDynamicPath = myDynamicPath + `&${key}=${value}`;
                        }
                    }
                    window.location = path.login + `?redirect_to=${window.location.pathname}${myDynamicPath}`;
                });
        } else {
            window.location = path.login;
            localStorage.removeItem(userData);
        }
    } 
    if(error.response.data.code === 'UNAUTHORIZED'){
        window.location.reload()
    }
    return Promise.reject(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persist}>
            <BrowserRouter basename={`/`}>
                <ToastContainer />
                <MainRoutes />
            </BrowserRouter>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);

// **************** Author: Bokhtyer Abid || bokhtyer.abid@shadhinlab.com *************** //
// **************** React 17 *************** //
// ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
